:root {
    font-family: 'Quicksand', sans-serif;
    --main-color-1: white;
    --main-color-2: lightblue;

    --main-text-color: black;
    --second-text-color: #3f464f;
    --third-text-color: #7a8da5;
    --fourth-text-color: #c8dbf5;

    --red: #ff4747;
    --orange: #ffa161;
    --green: #93cd93;
    --blue: #767fc9;
    --yellow: #EAEA47;
}

body {
    background: white;
    background-repeat: no-repeat;
    margin: 0;
    height: 100%;
    background-attachment:initial;
    
    font-family: 'Quicksand', sans-serif;

}

#background{
    
    position: absolute;
    object-fit: fill;
    min-width: 100vw;
    min-height: 100vh;
    
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

html {
    margin: 0;
    padding: 0;
}

h1 {
    font-family: 'Quicksand', sans-serif;
    font-size: 70pt;
    font-weight: normal;
    color: var(--main-text-color);
}

h2 {
    font-family: 'Quicksand', sans-serif;
    font-size: 30pt;
    font-weight: bolder;
    color: var(--second-text-color);
}

p {
    font-size: 20pt;
}

a {
    text-decoration:wavy;
}
.navitem{
    width: 100%;
    margin: 2vmin;
    color:black;

    font-size: 2.25cqw;
    text-align: center;
    font-weight: 400;
    font-size: 30pt;

    background-color: white;

    border-radius: 20pt;
    border-style: none;

    outline-color: black;
    outline-style: solid;
    outline-width: 3pt;

    font-family: 'Quicksand', sans-serif;
    text-decoration: none;
    transition: ease-out .1s;
    transform: translateY(-.5vmin) translateX(-.5vmin);
    box-shadow: 1vmin 1vmin 0px rgb(0, 0,0);
}

.navitem:hover{
    transition: all .05s;
    transform: translateY(-2pt) translateX(-2pt);
    box-shadow: 2pt 2pt 0px rgb(0, 0,0);
}
.navitem:active{
    transition: ease-out 0.025s;
    transform: translateY(0vmin) translateX(0vmin);
    box-shadow: 0vmin 0vmin 0px rgb(0, 0,0);
}

.soundcloud {
    font-size: 10px;
     color: #cccccc;
     word-break: normal;
     overflow: hidden;
     white-space: nowrap;
     text-overflow: ellipsis; 
     font-weight: 100;
}

.red{
    background-color: var(--red);
}
.blue{
    background-color: var(--blue);
}
.green{
    background-color: var(--green);
}
.orange{
    background-color: var(--orange);
}


.container {
    margin: auto;
    max-width: 75vw;
}

.fruitbasket-title {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.fruitbasket-title-img {
    width: min(40vw, 40vh);
    height: min(40vw, 40vh);
}

.fruitbasket-title-text {
    margin: 0;
    padding: 0;
}

.fruitbasket-nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}


.spacer-bottom {
    height: 1vw;
}

@media only screen and (max-width: 768px) {
    .container {
        max-width: 90vw;
    }
    .fruitbasket-nav{
        flex-direction: column;
    }
    
}

ul{
    display: table; 
    margin: 0 auto;
    text-align: left;
    padding: 0;
}

.imgbutton{
    width: 10vmin;
    height: 10vmin;
}
