:root {
  --main-color-1: white;
  --main-color-2: lightblue;
  --main-text-color: black;
  --second-text-color: #3f464f;
  --third-text-color: #7a8da5;
  --fourth-text-color: #c8dbf5;
  --red: #ff4747;
  --orange: #ffa161;
  --green: #93cd93;
  --blue: #767fc9;
  --yellow: #eaea47;
  font-family: Quicksand, sans-serif;
}

body {
  background: #fff no-repeat;
  background-attachment: initial;
  height: 100%;
  margin: 0;
  font-family: Quicksand, sans-serif;
}

#background {
  object-fit: fill;
  z-index: -1;
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

html {
  margin: 0;
  padding: 0;
}

h1 {
  color: var(--main-text-color);
  font-family: Quicksand, sans-serif;
  font-size: 70pt;
  font-weight: normal;
}

h2 {
  color: var(--second-text-color);
  font-family: Quicksand, sans-serif;
  font-size: 30pt;
  font-weight: bolder;
}

p {
  font-size: 20pt;
}

a {
  -webkit-text-decoration: none;
  text-decoration: none;
}

.navitem {
  color: #000;
  text-align: center;
  background-color: #fff;
  border-style: none;
  border-radius: 20pt;
  outline: 3pt solid #000;
  width: 100%;
  margin: 2vmin;
  font-family: Quicksand, sans-serif;
  font-size: 30pt;
  font-weight: 400;
  text-decoration: none;
  transition: all .1s ease-out;
  transform: translateY(-.5vmin)translateX(-.5vmin);
  box-shadow: 1vmin 1vmin #000;
}

.navitem:hover {
  transition: all 50ms;
  transform: translateY(-2pt)translateX(-2pt);
  box-shadow: 2pt 2pt #000;
}

.navitem:active {
  transition: all 25ms ease-out;
  transform: translateY(0)translateX(0);
  box-shadow: 0 0 #000;
}

.soundcloud {
  color: #ccc;
  word-break: normal;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 10px;
  font-weight: 100;
  overflow: hidden;
}

.red {
  background-color: var(--red);
}

.blue {
  background-color: var(--blue);
}

.green {
  background-color: var(--green);
}

.orange {
  background-color: var(--orange);
}

.container {
  max-width: 75vw;
  margin: auto;
}

.fruitbasket-title {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.fruitbasket-title-img {
  width: min(40vw, 40vh);
  height: min(40vw, 40vh);
}

.fruitbasket-title-text {
  margin: 0;
  padding: 0;
}

.fruitbasket-nav {
  flex-flow: row;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.spacer-bottom {
  height: 1vw;
}

@media only screen and (width <= 768px) {
  .container {
    max-width: 90vw;
  }

  .fruitbasket-nav {
    flex-direction: column;
  }
}

ul {
  text-align: left;
  margin: 0 auto;
  padding: 0;
  display: table;
}

.imgbutton {
  width: 10vmin;
  height: 10vmin;
}

/*# sourceMappingURL=index.fa257d0c.css.map */
